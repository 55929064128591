import React from "react";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import ContentSection from "../components/contentSection/ContentSection";
import Form from "../components/form/Form";
import Page from "../components/page/Page";
import RedirectBannerList from "../components/banner/RedirectBannerList";

const mandatoryPolicies = {
  candidatesPolicy: true
};

export default () => (
  <Page>
    <BreadCrumbs
      crumbsArray={[
        {
          name: "Careers",
          redirectUrl: "/careers"
        },
        {
          name: "Available positions",
          redirectUrl: "/jobs"
        },
        { name: "Spontaneous application" }
      ]}
    />
    <ContentSection
      backgroundColor="var(--color-blue)"
      description1={{
        description1:
          "We are constantly looking for talented individuals who wish to become part of and help expand our platform."
      }}
      textColor="var(--color-white)"
      title="Spontaneous application"
      titleTextColor="var(--color-white)"
    />
    <Form
      backgroundColor="var(--color-white)"
      formId="spontaneous-job-applications"
      mandatoryPolicies={mandatoryPolicies}
      formSections={[
        {
          label: "About you",
          questions: [
            {
              isMandatory: true,
              label: "Name",
              type: "Input"
            },
            {
              isMandatory: true,
              label: "Email",
              type: "Input"
            },
            {
              isMandatory: false,
              label: "Phone",
              type: "Input"
            }
          ]
        },
        {
          label: "Attach your Resume*",
          questions: [
            {
              isMandatory: true,
              label: "Please attach your Resume",
              type: "File"
            }
          ]
        },
        {
          label: "Attach your Cover Letter*",
          questions: [
            {
              isMandatory: true,
              label: "Please write your Cover Letter below",
              type: "Textarea"
            }
          ]
        }
      ]}
      successPageText="Your job application was submitted successfully."
      successPageTitle="Spontaneous application"
    />
    <RedirectBannerList
      banners={[
        {
          backgroundColor: "var(--color-blue-dark)",
          redirectUrl: "/jobs",
          textColor: "var(--color-white)",
          title: "See all available positions"
        },
        {
          backgroundColor: "var(--color-orange)",
          redirectUrl: "/events",
          textColor: "var(--color-white)",
          title: "Meet us at one of our events"
        }
      ]}
    />
  </Page>
);
